export type TGetImageURL = (image: any) => any;

export interface ISanityDataGetImage {
  getImageURL?: TGetImageURL;
  data?: any;
}

const isImagelessObject = (field: any) =>
  typeof field == "object" && field._type !== "imageWithMeta";

const isImage = (field: any) => {
  return typeof field == "object" && field._type && field._type === "imageWithMeta" && field.asset;
};

const isGlobalButton = (field: any) => {
  return typeof field == "object" && field._type && field._type === "globalButton";
};

const saveImage = async (field: any, getImageURL: TGetImageURL) => {
  // Build the URL for the image using Sanity's package
  const imageUrl = await getImageURL(field);
  let newField = { ...field };
  if (imageUrl) {
    newField = {
      ...field,
      imageUrl,
    };
  } else {
    console.error(`Erro em salvar uma imagem.`);
  }

  return newField;
};

const analyzeField = async (field: any, getImageURL: TGetImageURL) => {
  let finalField = field;
  for (const key of Object.keys(field)) {
    let newField = field[key];
    if (isImagelessObject(field[key])) {
      // if it's an object without an image, we want to go deeper
      // into its structure to check for images there
      newField = await analyzeField(newField, getImageURL);
    } else if (isImage(field[key])) {
      // If it's an image field with an asset, save the image
      newField = await saveImage(newField, getImageURL);
    } else if (isGlobalButton(field[key])) {
      // If not an object, we simply skip this key
      console.log("IS GLOBAL REF");
    } else {
      continue;
    }

    // swap out the previous field with the new one
    finalField = Object.assign(finalField, {
      [key]: newField,
    });
  }
  return finalField;
};

export const normalizeSanityData = ({ data, getImageURL }: ISanityDataGetImage) => {
  if (data && getImageURL) {
    return analyzeField(data, getImageURL);
  } else {
    return undefined;
  }
};
