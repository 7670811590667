import { Component } from "@components";
import { useStore } from "@state/store";
import { fetchDataFromSanity, subscribeToData } from "@util/previewFetch";
import { navigate } from "gatsby";
import * as queryString from "query-string";
import React from "react";

export class PreviewTemplate extends React.Component {
  // Initial state
  state = {
    isLoading: true,
    data: undefined,
  };

  // Method for fetching data and updating state
  public fetchData = async () => {
    // Parse the query from the location prop
    const query = queryString.parse(this.props.location.search);
    // Get the pageId and isDraft from the generated object
    const { pageId, isDraft } = query;
    // Fetch data from Sanity by using a helper function
    const sanityData = await fetchDataFromSanity(pageId, isDraft);

    // If there's data, send it to state
    if (sanityData) {
      this.setState({
        isLoading: false,
        data: sanityData,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  // When the component is first rendered, fetch data and,
  // if it's a draft, listen to changes
  public componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const { pageId, isDraft } = query;
    this.fetchData();
    if (isDraft) {
      // the subscribeToData helper function runs sanity-client's listen
      // method to create an observable that runs a callback function
      // every time the data is changed (in this case, this.fetchData)
      subscribeToData(pageId, this.fetchData);
    }
  }
  public render() {
    const {
      state: { isLoading, data },
    } = this;
    if (isLoading) {
      // If the data is still being fetched for the first time, return a loading
      // component (just a centralized h1 with "Loading..." as content)
      return <p>loading</p>;
    }
    if (!data) {
      navigate("/");
      return null;
    } else {
      // Finally, if it's not loading and there's data, render the desired
      // PageTemplate. Here you could do conditionals on the document type
      // and render different templates as needed ;)
      return (
        <div style={{ zIndex: 10, position: "relative" }}>
          {data.blocks &&
            data.blocks.map(component => {
              if (component == null) return null;
              return <Component data={component} />;
            })}
        </div>
      );
      //   <PageTemplate data={data} />;
    }
  }
}
