import { Match } from "@reach/router";
import { useStore } from "@state/store";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { PreviewTemplate } from "../templates/previewTemplate";

const PreviewPage = () => {
  const { setIsPreview } = useStore();

  useEffect(() => {
    setIsPreview(true);
  }, []);

  return (
    <>
      {/* I use React Helmet to add a meta tag that avoids indexing of this page */}
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {/*       
          @reach/router offers a <Match /> component that passes down router-related props
          to a child function. We use this function to either render the template if we're in
          the proper path - and pass the location prop to it -, or navigate to the homepage
        */}
      <Match path="/preview">
        {props => {
          if (!props.match) {
            navigate("/");
            return null;
          } else {
            return <PreviewTemplate location={props.location} />;
          }
        }}
      </Match>
    </>
  );
};

export default PreviewPage;
