const sanityClient = require("@sanity/client");
const imageUrlBuilder = require("@sanity/image-url");

// I store my query in another file for reusability with
// the `gatsby-source-plugin`
// const modularQueries = require('../../../../sanityQueries/modularQuery.js');
// import { removeWhitespace } from '../../../utils/strings';
import { normalizeSanityData } from "./normalise";

let timeToFetchAfterMutation: any;

export const subscribeToData = (pageId: string, callback: () => any) => {
  const client = sanityClient({
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
    token: process.env.SANITY_TOKEN,
    useCdn: true,
    withCredentials: true,
  });
  const query = `
  *[_id == 'drafts.${pageId}']`;
  client.listen(query, {}, { includeResult: false }).subscribe(() => {
    clearTimeout(timeToFetchAfterMutation);
    timeToFetchAfterMutation = setTimeout(() => {
      callback();
    }, 500);
  });
};

export const fetchDataFromSanity = async (pageId: string, isDraft: boolean = false) => {
  const client = sanityClient({
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
    token: process.env.SANITY_TOKEN,
    useCdn: true,
    // If it's a draft, we want to use credentials to
    // access non-published documents. In order for this
    // to work, you'll have to whitelist your domain!
    withCredentials: isDraft,
  });
  const query = `
  *[_id == '${isDraft ? `drafts.${pageId}` : pageId}']`;

  const crudeData = await client.fetch(query);

  if (crudeData && crudeData[0]) {
    const builder = imageUrlBuilder(client);
    const getImageURL = (image: any) => builder.image(image).url();

    const normalizedData = await normalizeSanityData({
      data: crudeData[0],
      getImageURL,
    });
    if (normalizedData) {
      return normalizedData;
    }
  } else {
    return {
      data: undefined,
      getImageURL: undefined,
    };
  }
};
